import React from 'react'
import ResumeMenu from '../Components/ResumeMenu';

function Regions(){
    return (
        <div className='App'>
        <div className="welcome-section">
        <img className="welcome-image" src={`${process.env.PUBLIC_URL}/Capital-Markets.jpeg`} alt="welcome" />
    </div>
    <ResumeMenu/>
    <div className="work-history-bar">
      <div className="work-history">
      <div>
      <h2>BACKGROUND</h2>
      Capital Markets Operations plays an essential role within a bank, particularly in the realm of real estate and lending. This division is responsible for executing and supporting trade activities and managing the operational aspects of financial transactions within real estate and lending capital markets. Its effectiveness directly influences the bank's ability to serve its clients, manage risk, and navigate the complexities of these markets.</div>
      <div>
      <h2>ROLE</h2>
      <h3>Operations Analyst <br/> Real Estate / Foreign Exchange </h3>
        </div>
      <div  className="myRole">
      <h2>My Role</h2>
      As an Associate Vice President in Capital Markets Operations at Regions Bank, I play a pivotal role in the operation of the Real Estate and Foreign Exchange (FX) lending portfolios. Every day, I deliver critical financial insights by crafting and presenting daily Profit & Loss (P&L) reports for Capital Markets Loan Trading to our senior leadership team.

I manage the syndicated FX loan book, which requires tight coordination with other major institutions. This involves overseeing fund disbursement and tracking, especially during frequent repricing cycles. I ensure the right transactions and hedges are set up for granting loans, showcasing my strategic financial insights and dedication to preserving the bank's financial stability.

A crucial part of my job is reconciling discrepancies in the interest and principal balances of loans, which is vital for safeguarding the bank's financial accuracy and reliability. I take pride in ensuring our bank's records meet regulatory standards, emphasizing the significance of my position within the bank.

In my role, I don't just maintain financial records and generate reports. I directly influence the bank's risk management strategy and the seamless operation of its capital market activities. My contributions are vital for ensuring operational efficiency and compliance, making a profound impact on the bank's performance in the capital markets.





</div>
    </div>
    </div>
    </div>
    )
}

export default Regions