// WelcomeSection.js
import React from 'react';

function WelcomeSection() {
  return (
    <div className="welcome-section">
      <img className="welcome-image" src={`${process.env.PUBLIC_URL}/welcomeImage.JPG`} alt="welcome" />
      <div className='welcome-message'>
        Hi, I'm Ndubuisi. I'm a finance professional focused on software engineering and data analytics.
      </div>
    </div>


  );
}

export default WelcomeSection;
