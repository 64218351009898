  import React from 'react';
  import '../style/App.css';

  function ProjectCard({image, title, summary, repoHref, demoHref}) {
    return (
      <div className="project-card">
        <img src={image} alt="Project"/>
        <h2>{title}</h2>
        <p>{summary}</p>
        <div className="overlay">
          <a href={demoHref} className="project-button">Demo</a>
          <a href={repoHref} className="project-button">Repository</a>
        </div>
      </div>
    );
  }
  

  export default ProjectCard;
